<template>
  <div class="position container">
    <div class="title">用户帐号管理</div>
    <section class="clearfix operate">
      <el-input
        class="input"
        size="small"
        placeholder="请输入厂商或用户名"
        prefix-icon="el-icon-search"
        v-model="accountValue"
        @change="searchValue"
      ></el-input>
      <el-button type="primary" class="fr" @click="addUser()">新增</el-button>
    </section>
    <div class="table">
      <el-table class="table" :data="accountData" style="margin-top: 40px">
        <el-table-column prop="username" label="用户名"> </el-table-column>
        <el-table-column prop="manufacturer_name" label="IDC厂商名称">
        </el-table-column>
        <el-table-column prop="contact_name" label="联系人" width="120">
        </el-table-column>
        <el-table-column prop="email" label="邮箱" width="150">
        </el-table-column>
        <el-table-column prop="phone" label="联系电话" width="120"> </el-table-column>
        <el-table-column prop="remark" label="备注"> </el-table-column>
        <el-table-column prop="status" label="状态" width="80">
            <template slot-scope="scope">
                <span :class="scope.row.status === 'normal' ? 'green' : 'red'">{{ scope.row.status === 'normal' ? '正常' : '禁用' }}</span>
            </template>
        </el-table-column>
        <el-table-column label="操作" width="120">
          <template slot-scope="scope">
            <span class="oprate-btn" @click="editUser(scope.row)">
              <i class="el-icon-edit-outline el-icon--right"></i>
              <span>编辑</span>
            </span>
            <span
              v-if="scope.row.status_zh === '正常'"
              class="oprate-btn"
              @click="forbid(scope.row)"
            >
              <i class="el-icon-circle-close el-icon--right"></i>
              <span>禁用</span>
            </span>
            <span v-else class="oprate-btn" @click="forbid(scope.row)">
              <i class="el-icon-switch-button el-icon--right"></i>
              <span>启用</span>
            </span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="fr pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.currentPage"
        :page-sizes="pagination.pageSizes"
        :page-size="pagination.pageSize"
        :total="pagination.total"
        layout="total, sizes, prev, pager, next, jumper"
      >
      </el-pagination>
    </div>
    <UserModal :userDialog.sync="userDialog" :isEdite="isEdite"  />
  </div>
</template>

<script>
import users from "/src/api/user.js";
import UserModal from "./UserModal";
export default {
  components: { UserModal },
  data() {
    return {
      accountData: [],
      accountValue: "",
      userDialog: false,
      isEdite:false,
      paginationCount: null,
      params: {
        page: 1,
        size: 20,
        keyword: "",
      },
      pagination: {
        total: 0,
        currentPage: 1,
        pageSize: 20,
        pageSizes: [10, 15, 20],
      },
    };
  },
  mounted() {
    this.$EventBus.$on('updateUser', (data) => {
        if (data) {
            this.getUserAccount(this.params)
            return
        }
    });
    this.getUserAccount();
  },
  watch: {
    currentPage(newVal) {
      this.params.page = newVal;
    },
  },
  methods: {
    searchValue() {
      if (this.accountValue.length) {
        this.params.keyword = this.accountValue;
      } else {
        this.params.keyword = "";
      }
      this.getUserAccount(this.params);
    },

    getUserAccount() {
      users.getUserAccount(this.params).then((item) => {
        this.accountData = item.list;
        this.pagination.total = item.count;
      });
    },

    handleSizeChange(val) {
      this.params.size = val;
      this.getUserAccount(this.params);
    },

    handleCurrentChange(val) {
      this.params.page = val;
      this.getUserAccount(this.params);
    },
    addUser() {
      this.isEdite = false
      this.userDialog = true;
    },

    editUser(row) {
      this.userDialog = true;
      this.isEdite = true;
      this.$EventBus.$emit('updateuUerDialog', row);
    },

    forbid(row) {
      this.$confirm(`<p>确定 ${row.status === 'normal' ? '禁用' : '启用'} 用户账号？</p>`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          dangerouslyUseHTMLString: true,
        }).then(() => {
            const status = row.status === 'normal' ? 1 : 0
            users.editUser({status: status}, row.id).then((data) => {
                this.$message({
                    message: '操作成功！',
                    type: 'success'
                });
                row.status = data.status;
                row.status_zh = data.status_zh;
            })
        }).catch(() => {         
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.position {
  overflow: scroll;
  .operate {
    margin: 10px 10px 40px 10px
  }
  .input {
    width: 20%;
  }
  .addInput {
    width: 80%;
  }
  .click {
    text-align: end;
    margin-right: 48px;
  }
  .oprate-btn:hover {
    cursor: pointer;
  }
  .pagination {
    margin: 40px 70px 0 0;
  }
}
</style>
