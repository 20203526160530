
<style lang="scss" scoped>
.user-dialog {
  overflow: scroll;
  .input {
    width: 20%;
    margin: 25px 0 0 73px;
  }
  .addInput {
    width: 80%;
  }
  .click {
    text-align: end;
    margin-right: 48px;
  }
}
</style>
<template>
    <div class="user-dialog">
        <el-dialog :title="isEdite ? '编辑用户' : '新增用户'" :before-close="closeDialog" :close-on-click-modal="false" :visible.sync="userDialog">
            <el-form
                ref="userForm"
                :model="userForm"
                :rules="rules"
                label-position="left"
                label-width="80px"
                :hide-required-asterisk="true"
            >
                <el-row>
                <el-col :span="12">
                    <el-form-item label="用户名" prop="username">
                    <el-input
                        class="addInput"
                        size="small"
                        v-model="userForm.username"
                        :disabled="isEdite"
                    ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="厂商名称" prop="manufacturer_name">
                    <el-input
                        class="addInput"
                        size="small"
                        v-model="userForm.manufacturer_name"
                        :disabled="isEdite"
                    ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item :label="isEdite ? '新密码' : '密码'" prop="password">
                    <el-input
                        class="addInput"
                        size="small"
                        v-model="userForm.password"
                        show-password
                    ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="联系人" prop="contact_name">
                    <el-input
                        class="addInput"
                        size="small"
                        v-model="userForm.contact_name"
                    ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="联系电话" prop="phone">
                    <el-input class="addInput" size="small" v-model="userForm.phone"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="邮箱" prop="email">
                    <el-input class="addInput" size="small" v-model="userForm.email"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item label="备注" prop="remark">
                    <el-input
                        size="small"
                        style="width: 92%"
                        v-model="userForm.remark"
                    ></el-input>
                    </el-form-item>
                </el-col>
                </el-row>
                <el-form-item class="click">
                    <el-button @click="closeDialog()">取消</el-button>
                    <el-button type="primary" @click="submitForm('userForm')">保存</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
import users from "/src/api/user.js";
export default {
    name: 'PriceModal',
    props: {
        userDialog: {
            type: [Boolean],
            default: false,
        },
        isEdite: {
            type: [Boolean],
            default: false,
        }
    },
  data() {
    let checkExist = (type, value, callback) => {
        const typeMap = {
            username: 'username',
            phone: 'phone',
            manufacturer_name: 'manufacturer_name',
            email: 'email'
        }
        users.checkExist({[typeMap[type]]: value}).then((data) => {
            if (!data.result) {
                return callback();
            } else {
                const typeMap = {
                    username: '用户名',
                    phone: '手机号',
                    manufacturer_name: '厂商名',
                    email: '邮箱'
                }
                return callback(new Error(`${typeMap[type]}已存在`));
            }
        }).catch(({data}) => {
            return callback(new Error(`${data.message}`));
        });
    }
    var checkUsername = (rule, value, callback) => {
        const reg = /^[a-z][a-z0-9_-]*$/
      if (!value) {
        return callback(new Error("用户名不能为空"));
      } else if (!reg.test(value)) {
        return callback(new Error("小写英文字符开头，支持小写英文、数字、下划线和中划线组合"));
      } else if (value.length < 5 || value.length > 20) {
        return callback(new Error("用户名限定 5 - 20 位字符"));
      } else {
        if (!this.isEdite) {
            checkExist('username', value, callback)
        } else {
            return callback();
        }
      }
    };
    var checkPhone = (rule, value, callback) => {
        const reg = /^1[3456789]\d{9}$/
        if (!value) {
            return callback(new Error("手机号不能为空"));
        } else if (!reg.test(value)) {
            callback(new Error("手机号格式错误"));
        } else {
            if (!this.isEdite) {
                checkExist('phone', value, callback)
            } else {
                return callback();
            }
        }
    };
    var checkPass = (rule, value, callback) => {
        const reg = /\D/
        if (!value) {
            if (this.isEdite) {
                return callback();
            } else {
                return callback(new Error("密码不能为空"));
            }
        } else if (!reg.test(value)) {
            callback(new Error("为了账号安全，不允许使用全数字密码"));
        } else if (value.length < 8 || value.length > 20) {
            return callback(new Error("密码支持 8 - 20 位的字母、数字和英文符号"));
        } else {
            return callback();
        }
    };
    var checkEmail = (rule, value, callback) => {
        const reg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/
        if (!value) {
            return callback(new Error("邮箱不能为空"));
        } else if (!reg.test(value)) {
            callback(new Error("邮箱格式错误"));
        } else {
            if (!this.isEdite) {
                checkExist('email', value, callback)
            } else {
                return callback();
            }
        }
    };
    var checkManufacturer = (rule, value, callback) => {
        if (!value) {
            return callback(new Error("厂商名称不能为空"));
        } else if (value.length < 1 || value.length > 20) {
            callback(new Error("厂商名称限定 1 - 20 位字符"));
        } else {
            if (!this.isEdite) {
                checkExist('manufacturer_name', value, callback)
            } else {
                return callback();
            }
        }
    };
    return {
        roomName: '',
        userForm: {},
        errorData: null,
        rules: {
            username: [
                {required: true, validator: checkUsername, trigger: "blur"},
            ],
            password: [
                {required: true, validator: checkPass, trigger: "blur"},
            ],
            phone: [
                { required: true, validator: checkPhone, trigger: "blur" },
            ],
            email: [
                { required: true, validator: checkEmail, trigger: "blur" },
            ],
            manufacturer_name: [
                { required: true, validator: checkManufacturer, trigger: "blur" },
            ],
            contact_name: [
                { required: true, message: "联系人不能为空", trigger: "blur" },
                { min: 1, max: 20, message: "联系人限定 1 - 20 位字符", trigger: "blur" },
            ],
            remark: [
                { min: 0, max: 20, message: "备注名限定 0 - 20 位字符", trigger: "blur" },
            ]
        },
    };
  },
  watch: {
  },
  mounted() {
    this.$EventBus.$on('updateuUerDialog', (data) => {
        this.userForm = JSON.parse(JSON.stringify(data))
    });
  },
  methods: {
    closeDialog() {
        this.$emit('update:userDialog', false);
        this.$refs['userForm'].resetFields();
        this.$refs['userForm'].clearValidate();
        this.userForm = {}
    },
    submitForm(formName) {
        const params = {
            username: this.userForm.username,
            manufacturer_name: this.userForm.manufacturer_name,
            password: this.userForm.password,
            contact_name: this.userForm.contact_name,
            phone: this.userForm.phone,
            email: this.userForm.email,
            remark: this.userForm.remark
        }
        this.$refs[formName].validate((valid) => {
            if (valid) {
                if (!this.isEdite) { // 创建
                    this.addUser(params)
                } else { // 编辑
                    this.editUser(params, this.userForm.id)
                }
            } else {
                console.log('error submit!!');
                return false;
            }
        });
    },

    addUser(params) {
        users.createUser(params).then(() => {
            this.$EventBus.$emit('updateUser', true);
            this.$message("创建成功！");
            this.closeDialog();
        }).catch(({data}) => {
            this.errorData = data
            // this.$message.error(data.message);
        });
    },

    editUser(params, id) {
        if (!params.password) {
            delete params.password
        }
        users.editUser(params, id).then(() => {
            this.$EventBus.$emit('updateUser', true);
            this.$message("修改成功！");
            this.closeDialog();
        }).catch(({data}) => {
            this.$message.error(data.message);
        });
    },
  }
}
</script>
