import instance from './index'

const users = {
  async getUser() {
    return instance('/api/v1/me', {
      method: 'GET'
    }).then(data => {
      return data
    })
  },
  async getUserAccount(params) {
    return instance('/api/v1/users', {
      method: 'GET',
      params: params
    }).then(data => {
      return data
    })
  },
  async createUser(params) {
    return instance('/api/v1/users', {
      method: 'POST',
      data: params
    }).then(data => {
      return data
    })
  },
  async editUserInfo (userId){
    return instance(`/api/v1/users/${userId}`,{
      method:'GET',
    })
  }, 
  async editUser(params, id) {
    return instance(`/api/v1/users/${id}`, {
      method: 'PUT',
      data: params
    }).then(data => {
      return data
    });
  },
  async getLog(params, startTime, endTime) {
    return instance('/api/v1/logs', {
      method: 'GET',
      params: params,
      startTime: startTime,
      endTime: endTime,
    }).then(data => {
      return data
    })
  },
  async checkExist(params) {
    return instance('/api/v1/exist/user', {
      method: 'GET',
      params: params,
    }).then(data => {
      return data
    })
  }
}

export default users
